'use client';

import { FC } from 'react';
import { useSelectedLayoutSegment } from 'next/navigation';

import ShowElement from 'components/atoms/ShowElement';
import NextLink from 'components/atoms/NextLink';
import {
  FilterCategoryWrapper,
  HeadingCategory
} from 'components/organisms/FilterCategory/styles';
import { IFilterCategoryProps } from './types';
import { Routes } from 'common/constants/common';
import { colors } from 'styles/variables.module';
import { createDataTestAttribute } from 'utils/helpers/automation';
import { DataTestAttributes } from 'utils/helpers/automation/types';

const FilterCategory: FC<IFilterCategoryProps> = ({
  title,
  selectedMenuItem,
  menuItems,
  defaultSelectedKeys = []
}) => {
  const selectedSegment = useSelectedLayoutSegment();
  const selectedMenu = selectedMenuItem || selectedSegment;
  const changedStructureMenuItems = menuItems.map(({ label, key }) => ({
    id: key,
    key,
    label: (
      <NextLink
        data-test={createDataTestAttribute({
          dataTestAttribute: DataTestAttributes.Link,
          prefix: label
        })}
        href={`${Routes.CATEGORY.pathname}/${key}`}
        title={label}
      >
        {label}
      </NextLink>
    )
  }));

  return (
    <FilterCategoryWrapper
      items={[
        {
          label: (
            <ShowElement isShow={Boolean(title)}>
              <HeadingCategory
                text={title}
                color={colors.gray_9}
                size="large"
                fontWeight="bold"
              />
            </ShowElement>
          ),
          type: 'group',
          children: changedStructureMenuItems
        }
      ]}
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={[String(selectedMenu)]}
    />
  );
};

export default FilterCategory;
