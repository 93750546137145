import kebabCase from 'lodash/kebabCase';
import { DataTestAttributeCreatorArgs } from './types';

export const createDataTestAttribute = ({
  dataTestAttribute,
  prefix = '',
  suffix = ''
}: DataTestAttributeCreatorArgs) => {
  return `${prefix ? `${kebabCase(prefix)}-` : ''}${dataTestAttribute}${
    suffix ? `-${suffix}` : ''
  }`;
};
