'use client';
import { FC } from 'react';
import Space from 'antd/lib/space';

import useTranslations from 'hooks/common/useTranslations';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { ILoginOrSignUpProps } from './types';
import { EnvConfig } from 'configs/env';

const LoginOrSignUp: FC<ILoginOrSignUpProps> = ({ onLogIn }) => {
  const { t } = useTranslations('common');

  const signUpHandler = () => {
    window.open(`${EnvConfig.accountsClient}/sign-up`, '_self');
  };

  return (
    <Space>
      <Icon
        onClick={() => onLogIn()}
        name="mobileLoginIcon"
        className="mobileLoginAndSearchIcons"
        width={24}
        height={24}
      />
      <Button
        dataTest="log-in"
        size="middle"
        type="default"
        target="_blank"
        text={t('logIn')}
        onClick={() => onLogIn()}
      />
      <Button
        dataTest="sign-up"
        text={t('signUp')}
        type="primary"
        size="middle"
        onClick={signUpHandler}
      />
    </Space>
  );
};

export default LoginOrSignUp;
