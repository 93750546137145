'use client';

import { FC, useEffect, useRef, useState } from 'react';

import SearchInput from 'components/atoms/SearchInput';
import DropDown from 'components/atoms/DropDown';
import { ISearchInputWithDropDown } from 'components/molecules/SearchInputWithDropDown/types';
import { scrollableTargetForSearchDropDown } from 'common/constants/common';
import { OnChangeHandlerType } from 'common/globalTypes/utilityTypes/common';
import { SearchTypeEnum } from 'components/atoms/SearchInput/types';
import { EmptyStateSearchResultWrapper } from './styles';
import useTranslations from 'hooks/common/useTranslations';
import Icon from 'components/atoms/Icon';
import BodyText from 'components/atoms/BodyText';
import { createDataTestAttribute } from 'utils/helpers/automation';
import { DataTestAttributes } from 'utils/helpers/automation/types';

const SearchInputWithDropDown: FC<ISearchInputWithDropDown> = ({
  onPressEnter,
  onChangeSearch,
  foundApps,
  loading,
  onSelect
}) => {
  const { t } = useTranslations('common');
  const [isVisibleDropDown, setIsVisibleDropDown] = useState(false);
  const [internalStateSearchInput, setInternalStateSearchInput] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isLoadingStateAndTyped =
    !loading && internalStateSearchInput.length > 0;

  const notFoundAppAndIsLoadingStateAndTyped =
    foundApps.length === 0 && isLoadingStateAndTyped;

  const dropDownVisible = Boolean(isLoadingStateAndTyped && isVisibleDropDown);

  const emptyStateSearchResult = notFoundAppAndIsLoadingStateAndTyped && {
    dropdownRender: () => (
      <EmptyStateSearchResultWrapper>
        <Icon name="emptyState" width={100} height={100} />
        <BodyText size="base" fontWeight="bold" text={t('noSearchResult')} />
      </EmptyStateSearchResultWrapper>
    )
  };

  const handleVisibleChange = (visible: boolean) => {
    setIsVisibleDropDown(visible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const dropDownContainer = dropdownRef.current;

    if (
      dropDownContainer &&
      !dropDownContainer.contains(event.target as Node)
    ) {
      setIsVisibleDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onChangeInput = (event: OnChangeHandlerType) => {
    onChangeSearch(event.target.value);
    setInternalStateSearchInput(event.target.value);
  };

  return (
    <div ref={dropdownRef}>
      <DropDown
        trigger={['click']}
        open={dropDownVisible}
        onOpenChange={handleVisibleChange}
        menu={{
          items: foundApps, // dataTEST
          onClick: onSelect,
          className: scrollableTargetForSearchDropDown,
          style: { height: 200, overflowY: 'auto' }
        }}
        {...emptyStateSearchResult}
      >
        <SearchInput
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Input,
            prefix: 'search'
          })}
          loading={loading}
          onPressEnter={onPressEnter}
          onChange={onChangeInput}
          searchType={SearchTypeEnum.main}
        />
      </DropDown>
    </div>
  );
};

export default SearchInputWithDropDown;
