'use client';

import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import { SelectOptionStyleWrapper, SelectStyleWrapper } from './styles';
import { ISelectProps } from './types';
import { createDataTestAttribute } from 'utils/helpers/automation';
import { DataTestAttributes } from 'utils/helpers/automation/types';

const Select: FC<ISelectProps> = ({
  data,
  keyProp,
  valueProp,
  textProp,
  loading = false,
  disabled,
  ...props
}) => {
  return (
    <SelectStyleWrapper
      disabled={loading || disabled}
      loading={loading}
      {...props}
      size="large"
    >
      {data?.map((item: any) => (
        <SelectOptionStyleWrapper
          key={item[keyProp]}
          value={item[valueProp]}
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Option,
            prefix: item[textProp]
          })}
        >
          <BodyText size="small" fontWeight="regular">
            {item[textProp]}
          </BodyText>
        </SelectOptionStyleWrapper>
      ))}
    </SelectStyleWrapper>
  );
};

export default Select;
