'use client';
import { FC } from 'react';

import { ButtonStyleWrapper } from './styles';
import { IButtonProps } from './types';
import { createDataTestAttribute } from 'utils/helpers/automation';
import { DataTestAttributes } from 'utils/helpers/automation/types';

const Button: FC<IButtonProps> = ({
  size = 'small',
  shape,
  danger = false,
  icon,
  onClick,
  loading,
  text,
  href = '',
  block,
  target = '_blank',
  children,
  type,
  style,
  disabled,
  dataTest
}) => {
  const buttonProps = {
    size,
    style,
    shape,
    danger,
    type,
    icon,
    block,
    disabled,
    loading
  };

  if (href) {
    return (
      <a href={href} target={target}>
        <ButtonStyleWrapper
          data-test={createDataTestAttribute({
            dataTestAttribute: DataTestAttributes.Button,
            prefix: dataTest
          })}
          {...buttonProps}
        >
          {text || children}
        </ButtonStyleWrapper>
      </a>
    );
  }

  return (
    <ButtonStyleWrapper
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Button,
        prefix: dataTest
      })}
      onClick={onClick}
      {...buttonProps}
    >
      {text || children}
    </ButtonStyleWrapper>
  );
};

export default Button;
