import { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import LoadingWrapper from 'components/atoms/LoadingWrapper';
import Icon from 'components/atoms/Icon';
import {
  ISearchInputProps,
  SearchTypeEnum
} from 'components/atoms/SearchInput/types';
import { SearchInputStyle } from 'components/atoms/SearchInput/styles';
import useTranslations from 'hooks/common/useTranslations';
import { createDataTestAttribute } from 'utils/helpers/automation';
import { DataTestAttributes } from 'utils/helpers/automation/types';

const SearchInput: FC<ISearchInputProps> = ({
  searchType = SearchTypeEnum.default,
  loading,
  placeholder = 'searchForAnApp',
  dataTest,
  ...props
}) => {
  const { t } = useTranslations('common');
  const isDefaultSearchType = searchType === SearchTypeEnum.default;

  return (
    <SearchInputStyle
      data-test={createDataTestAttribute({
        dataTestAttribute: DataTestAttributes.Input,
        prefix: dataTest
      })}
      data-searchtype={searchType}
      data-loading={loading}
      prefix={<Icon name="search" width={16} height={16} />}
      allowClear={{
        clearIcon: (
          <LoadingWrapper
            loading={loading}
            indicator={<LoadingOutlined rev={undefined} />}
          >
            <Icon name="close" width={13} height={13} />
          </LoadingWrapper>
        )
      }}
      placeholder={t(placeholder)}
      data-search={isDefaultSearchType}
      {...props}
    />
  );
};

export default SearchInput;
